import React, { useEffect, useState } from 'react';
import './Saved.css';
import { useAuth } from '../../Context/Authcontext';
import Signupcomp from '../../Components/Signupcomp/Signupcomp';
import Listingcard from '../../Components/Listingcard/Listingcard';
import { Helmet, HelmetProvider } from 'react-helmet-async';

function Saved() {

    const { islogged } = useAuth();
    const { interprofile } = useAuth();
    const [savedids, setSavedids] = useState([]);
    const [savedpros, setSavedpros] = useState([]);

    useEffect(()=> window.scrollTo(0,0), []);

    const canonicalurl = window.location.href;

    useEffect(()=> {
        if(interprofile){
            setSavedids(interprofile?.savedpro);
        }
    }, [interprofile]);

    useEffect(()=> {
        if(savedids && savedids.length > 0){
            Promise.all(savedids.map(id => fetch(`https://azdawaj.com/api/user/${id}`).then(res => res.json()))).then(data => {
                setSavedpros(data.reverse());
            }).catch(err => console.log('error occured while getting all the prommises ', err));
        }
    }, [savedids]);

    // useEffect(()=> console.log(savedids), [savedids]);

  return (
    <HelmetProvider>
         <div className='main-saved'>
            <Helmet>
                <link rel="canonical" href={canonicalurl} />
            </Helmet>
            <div className="container row mx-auto mt-4">
                <p className="heading fs-4 body-font-bold mb-3">Saved Profiles</p>
                {
                    islogged? (
                        <div className="row p-0">
                            {
                                savedpros?.map((pro, ind)=> (
                                    <div key={ind} className="col-md-4 mb-3">
                                        <Listingcard userid={pro.randomid} blur={pro.profileblur} img={`https://azdawaj.com/uploads/${pro.profileimage}`} firstname={pro.firstname} lastname={pro.lastname} proffessoin={pro.jobtitle} age={pro.dob} location={pro.currentlocation}/>
                                    </div>
                                ))
                            }
                        </div>
                    ):(
                        <div className="w-100 p-0">
                            <Signupcomp/>
                        </div>
                    )
                }
            </div>
        </div>
    </HelmetProvider>
  )
}

export default Saved