import React, { useState, useEffect, useRef } from 'react';
import './Requests.css';
import { useAuth } from '../../Context/Authcontext';
import Signupcomp from '../../Components/Signupcomp/Signupcomp';
import Listingcard from '../../Components/Listingcard/Listingcard';
import { Helmet, HelmetProvider } from 'react-helmet-async';

function Requests() {

    const {islogged} = useAuth();
    const {userid} = useAuth();
    // const {setAcceptedreqs} = useAuth();
    const {interprofile} = useAuth();
    const {setReqcount} = useAuth();
    const {setIsreq} = useAuth();
    // const {encodeJSON} = useAuth();
    // const {acceptedreqs} = useAuth();
    const [profiledata, setProfiledata] = useState({});
    const [sentreqs, setSentreqs] = useState([]);
    const [recireqs, setRecireqs] = useState([]);
    const [accreqs, setAccreqs] = useState([]);
    const [reqtab, setReqtab] = useState(0);
    const [unmounting, setUnmounting] = useState(false);
    const [lastarr, setLastarr] = useState([]);

    const rendercount = useRef(0);

    const canonicalurl = window.location.href;

    useEffect(()=> {
        setProfiledata(interprofile);
        rendercount.current += 1;
        if(rendercount.current === 1){
            // console.log('this is the render current profile', interprofile);
            setLastarr(interprofile?.reqreceived);
            // console.log(interprofile?.reqreceived);
        }
    }, [interprofile]);

    useEffect(()=> {
        // console.log('page mounted');
        setIsreq(true);
        if(lastarr?.length > 0){
            fetch(`https://azdawaj.com/api/reqcount/${userid}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({"lastreq": lastarr})
            }).then(res => res.json()).then(data => {
                // console.log(data);
                setReqcount(data.reqcount);
            }).catch(err => console.log('error occured while sending the update to lastreq ', err));
            // setLastseenobj(interprofile)
        }else{
            // console.log('lastarr is smaller than 0')
        }
        return () => {
            // setUnmounting(true);
            // console.log('unmounting now')
            setIsreq(false);
        };
    }, [setIsreq, lastarr, userid, setReqcount]);

    // useEffect(()=> console.log(unmounting), [unmounting])

    useEffect(()=>{
        if(unmounting){
            setUnmounting(false)
            // console.log('this is awesome, ', interprofile)
        }
    }, [unmounting, interprofile])

    // useEffect(()=>{
    //     setUnmounting(false);
    // }, [interprofile])

    useEffect(()=>{
        if(profiledata && profiledata.reqsent){
            Promise.all(profiledata.reqsent.map(id => fetch(`https://azdawaj.com/api/profile/${id}`).then(res => res.json())))
            .then(dataarr => {
                setSentreqs(dataarr.reverse());
                // console.log(dataarr);
            }).catch(err => console.log('error occured while getting the promise array ', err))
        }
    }, [profiledata])

    useEffect(()=> {
        if(profiledata && profiledata.reqreceived){
            Promise.all(profiledata.reqreceived.map(id => fetch(`https://azdawaj.com/api/profile/${id}`).then(res => res.json())))
            .then(dataarr => {
                setRecireqs(dataarr.reverse());
                // console.log(dataarr);
            }).catch(err => console.log('error occured while getting the promise array ', err))
        }
    }, [profiledata]);

    useEffect(()=> {
        if(profiledata && profiledata.reqaccepted){
            Promise.all(profiledata.reqaccepted.map(id => fetch(`https://azdawaj.com/api/profile/${id}`).then(res => res.json())))
            .then(dataarr => {
                setAccreqs(dataarr.reverse());
                // console.log(dataarr);
            }).catch(err => console.log('error occured while getting the promise array ', err))
        }
    }, [profiledata]);

    const findStatus = (id) => {
        const foundId = profiledata.reqstatus.find(e => e.rid === id);
        // console.log('this is found id ', foundId);
        if(foundId){
            // console.log(foundId);
            return foundId.status;
        }else{
            return 'pending';
        }
    }

    // useEffect(()=> console.log('thsi is req array ', sentreqs), [sentreqs]);

  return (
    <HelmetProvider>
        <div className='main-requests pb-5'>
            <Helmet>
                <link rel="canonical" href={canonicalurl} />
            </Helmet>
            <div className="container mt-4 pb-5">
                
                {
                        islogged? (
                            <div className="wraper">
                                <h2 className="heading mb-5">Requests</h2>
                                <div className="request-tabs-wrap d-flex mb-3">
                                    <div className={`req-tab p-2 px-3 rounded-pill me-2 mb-2 body-font-bold cursor-pointer ${reqtab === 0? 'btn-brand-pink': 'btn-outline-brand-pink'}`} onClick={()=> setReqtab(0)}>Received</div>
                                    <div className={`req-tab p-2 px-3 rounded-pill me-2 mb-2 body-font-bold cursor-pointer ${reqtab === 1? 'btn-brand-pink': 'btn-outline-brand-pink'}`} onClick={()=> setReqtab(1)}>Sent</div>
                                    <div className={`req-tab p-2 px-3 rounded-pill me-2 mb-2 body-font-bold cursor-pointer text-nowrap ${reqtab === 2? 'btn-brand-pink': 'btn-outline-brand-pink text-nowrap'}`} onClick={()=> setReqtab(2)}>You Accepted</div>
                                </div>
                                <div className={`${reqtab === 0? 'd-block': 'd-none'}`}>
                                    <div className="row">
                                        {
                                            recireqs?.length > 0? (
                                                recireqs?.map((pro, ind) => (
                                                    <div key={ind} className="col-md-4 mb-3">
                                                        <Listingcard num={0} sid={pro._id} blur={pro.profileblur} userid={pro.randomid} img={`https://azdawaj.com/uploads/${pro.profileimage}`} firstname={pro.firstname} lastname={pro.lastname} proffessoin={pro.jobtitle} age={pro.dob} location={pro.currentlocation}/>
                                                    </div>
                                                ))
                                            ): (
                                                <p className="mb-0 text-center fs-5 mt-5 body-font-bold text-muted">No requests yet!</p>
                                            )
                                        }
                                    </div>
                                </div>
                                <div className={`${reqtab === 1? 'd-block': 'd-none'}`}>
                                    <div className="row">
                                        {
                                            sentreqs?.length > 0 ? (
                                                sentreqs?.map((pro, ind) => (
                                                    <div key={ind} className="col-md-4 mb-3">
                                                        <Listingcard num={1} sid={pro._id} blur={pro.profileblur} userid={pro.randomid} status={findStatus(pro._id)} img={`https://azdawaj.com/uploads/${pro?.profileimage}`} firstname={pro.firstname} lastname={pro.lastname} proffessoin={pro.jobtitle} age={pro.dob} location={pro.currentlocation}/>
                                                    </div>
                                                ))
                                            ): (
                                                <p className="mb-0 text-center fs-5 mt-5 body-font-bold text-muted">Sent requests will appear here</p>
                                            )
                                        }
                                    </div>
                                </div>
                                <div className={`${reqtab === 2? 'd-block': 'd-none'}`}>
                                    <div className="row">
                                        {
                                            accreqs?.length > 0 ? (
                                                accreqs?.map((pro, ind) => (
                                                    <div key={ind} className="col-md-4 mb-3">
                                                        <Listingcard num={2} sid={pro._id} blur={pro.profileblur} userid={pro.randomid} img={`https://azdawaj.com/uploads/${pro?.profileimage}`} firstname={pro.firstname} lastname={pro.lastname} proffessoin={pro.jobtitle} age={pro.dob} location={pro.currentlocation}/>
                                                    </div>
                                                ))
                                            ):(
                                                <p className="mb-0 text-center fs-5 mt-5 body-font-bold text-muted">Your accepted requests will appear here</p>
                                            )
                                        }
                                    </div>
                                </div>
                            </div>
                        ): (
                            <div className="w-100 p-0">
                                <Signupcomp/>
                            </div>
                        )
                    }
            </div>
        </div>
    </HelmetProvider>
  )
}

export default Requests