import React from 'react'
import { Link } from 'react-router-dom';
import './Notfound.css'

const Notfound = () => {
  return (
    <div className='main-notfound'>
        <div className="py-5 mx-auto notfound-img">
            <Link to='/' className='nav-link'>
                <img src="/assets/404.png" alt="page not found" className="img-fluid" />
            </Link>
        </div>
    </div>
  )
}

export default Notfound