import React, {useEffect, useState} from 'react';
import { useAuth } from '../../Context/Authcontext';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import Listingcard from '../../Components/Listingcard/Listingcard';

function Accepted() {

    const { islogged, interprofile } = useAuth();
    const [acceptedProfiles, setAcceptedProfiles] = useState([]);
    const canonicalurl = window.location.href;

    useEffect(() => {
        if (interprofile) {
            const fetchAcceptedProfiles = async () => {
                try {
                    // Fetch profiles from reqaccepted
                    const acceptedProfilesData = await Promise.all(
                        interprofile.reqaccepted.map(id =>
                            fetch(`https://azdawaj.com/api/profile/${id}`).then(res => res.json())
                        )
                    );

                    // Fetch profiles with status 'accepted' in reqstatus
                    const statusAcceptedProfilesData = await Promise.all(
                        interprofile.reqstatus
                            .filter(status => status.status === 'accepted') // Filter for accepted status
                            .map(status =>
                                fetch(`https://azdawaj.com/api/profile/${status.rid}`).then(res => res.json())
                            )
                    );

                    // Combine both arrays and remove duplicates
                    const combinedProfiles = [
                        ...acceptedProfilesData,
                        ...statusAcceptedProfilesData
                    ];
                    const uniqueProfiles = Array.from(
                        new Map(combinedProfiles.map(profile => [profile._id, profile])).values()
                    );

                    // Update state
                    setAcceptedProfiles(uniqueProfiles.reverse());
                } catch (err) {
                    console.error('Error occurred while fetching accepted profiles:', err);
                }
            };

            fetchAcceptedProfiles();
        }
    }, [interprofile]);

    return (
        <HelmetProvider>
            <div className="main-accepted-requests pb-5">
                <Helmet>
                    <link rel="canonical" href={canonicalurl} />
                </Helmet>
                <div className="container mt-4 pb-5">
                    {islogged ? (
                        <div className="wraper">
                            <h2 className="heading mb-5 fs-4">Matched Profiles</h2>
                            <div className="row">
                                {acceptedProfiles?.length > 0 ? (
                                    acceptedProfiles.map((pro, ind) => (
                                        <div key={ind} className="col-md-4 mb-3">
                                            <Listingcard num={2} sid={pro._id} blur={pro.profileblur} userid={pro.randomid} img={`https://azdawaj.com/uploads/${pro.profileimage}`} firstname={pro.firstname} lastname={pro.lastname} proffessoin={pro.jobtitle} age={pro.dob} location={pro.currentlocation} />
                                        </div>
                                    ))
                                ) : (
                                    <p className="mb-0 text-center fs-5 mt-5 body-font-bold text-muted">
                                        No matches yet!
                                    </p>
                                )}
                            </div>
                        </div>
                    ) : (
                        <div className="w-100 p-0">
                            <p className="text-center">Please log in to view your accepted requests.</p>
                        </div>
                    )}
                </div>
            </div>
        </HelmetProvider>
    )
}

export default Accepted